import * as React from "react"
import Footer from "./Footer";
import Navigation from "./Navigation";
import TekHeader from "./TekHeader";






export default function Layout({ children }) {
    return (
       <>

        
       
            {children}
       
       <Footer/>
     
       

       </>
    );
  }