import React from "react";
import { PortableText } from "@portabletext/react";

const PortableTextComponents = {
    block: {
      // Ex. 1: custom renderer for the em / italics decorator
  
      h1: ({ children }) => {
        return (
          <h1 className="Portable text-red-600">{children}</h1>
  
        )
      },
      h2: ({ children }) => {
        return (
          <h2 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{children}</h2>
  
        )
      },
      li: ({ children }) => {
        return (
          <ul className="mt-xl">{children}</ul>
  
        )
      },
      normal: ({ children }) => {
        return (
          <p className="portable-P">{children}</p>
        )
      },
  
      strong: ({ children }) => {
        return (
          <strong className="font-bold">{children}</strong>
        )
      }
  
    },
    list: {
      // Ex. 1: customizing common list types
      bullet: ({ children }) => <ul className="mt-3 list-disc list-inside">{children}</ul>,
      number: ({ children }) => <ol className="mt-3 list-decimal list-inside">{children}</ol>,
  
      // Ex. 2: rendering custom lists
      checkmarks: ({ children }) => <ol className="m-auto text-lg">{children}</ol>,
    },
    listItem: {
      // Ex. 1: customizing common list types
      bullet: ({ children }) => <li className="ml-4">{children}</li>,
  
      // Ex. 2: rendering custom list items
      checkmarks: ({ children }) => <li>✅ {children}</li>,
    },
  }


export default PortableTextComponents;