import React from "react";

export default function Logo(){
    return(

<svg id="Ebene_1" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 207.68 21.49" width="250">
  <path d="m42.33,9.5h17.38l-.74,3.9h-17.38l.74-3.9h0Zm-4.44,6.41l1.67-8.82c.14-.73.84-1.32,1.57-1.32h21.1s.76-4.21.76-4.21h-25.19c-2.34,0-4.62,1.92-5.06,4.26l-2.14,11.31c-.44,2.34,1.11,4.26,3.45,4.26h25.37s.75-4.15.75-4.15h-21.21c-.72,0-1.21-.6-1.07-1.32h0Z" fill="#9c9b9b" fillRule="evenodd"/>
  <path d="m42.33,9.5h17.38l-.74,3.9h-17.38l.74-3.9h0Zm-4.44,6.41l1.67-8.82c.14-.73.84-1.32,1.57-1.32h21.1s.76-4.21.76-4.21h-25.19c-2.34,0-4.62,1.92-5.06,4.26l-2.14,11.31c-.44,2.34,1.11,4.26,3.45,4.26h25.37s.75-4.15.75-4.15h-21.21c-.72,0-1.21-.6-1.07-1.32h0Z" fill="#9c9b9b" fillRule="evenodd"/>
  <path d="m184.07,21.31c.55,0,1.05,0,1.53-.01,1.3-.03,2.58-.76,3.87-2.48l7.87-9.12-2.12,11.6h7.33s3.62-19.82,3.62-19.82h-7.21l-12.84,14.63c-.47.54-.86.8-1.25.8-.39,0-.68-.26-.96-.8l-7.49-14.63h-7.21l-3.62,19.82h7.33s2.12-11.59,2.12-11.59l4.54,9.12c.67,1.72,1.67,2.45,2.97,2.48.48.01.97.01,1.52.01h0Z" fill="#9c9b9b" fillRule="evenodd"/>
  <path d="m184.07,21.31c.55,0,1.05,0,1.53-.01,1.3-.03,2.58-.76,3.87-2.48l7.87-9.12-2.12,11.6h7.33s3.62-19.82,3.62-19.82h-7.21l-12.84,14.63c-.47.54-.86.8-1.25.8-.39,0-.68-.26-.96-.8l-7.49-14.63h-7.21l-3.62,19.82h7.33s2.12-11.59,2.12-11.59l4.54,9.12c.67,1.72,1.67,2.45,2.97,2.48.48.01.97.01,1.52.01h0Z" fill="#9c9b9b" fillRule="evenodd"/>
  <path d="m154.88,5.68l.77-4.21h-17.01c-2.34,0-4.61,1.92-5.03,4.26l-2.07,11.31c-.43,2.34,1.14,4.26,3.48,4.26h23.1c2.34,0,4.61-1.92,5.03-4.26l2.07-11.31c.43-2.34-1.14-4.26-3.48-4.26h-2.28c-.26,1.42-.6,3.29-1.02,5.56l-.55,3.02-1.05,5.74c-.14.74-.86,1.35-1.6,1.35h-15.8c-.74,0-1.24-.61-1.11-1.35l1.6-8.76c.14-.74.86-1.35,1.6-1.35h13.34Z" fill="#9c9b9b" fillRule="evenodd"/>
  <path d="m154.88,5.68l.77-4.21h-17.01c-2.34,0-4.61,1.92-5.03,4.26l-2.07,11.31c-.43,2.34,1.14,4.26,3.48,4.26h23.1c2.34,0,4.61-1.92,5.03-4.26l2.07-11.31c.43-2.34-1.14-4.26-3.48-4.26h-2.28c-.26,1.42-.6,3.29-1.02,5.56l-.55,3.02-1.05,5.74c-.14.74-.86,1.35-1.6,1.35h-15.8c-.74,0-1.24-.61-1.11-1.35l1.6-8.76c.14-.74.86-1.35,1.6-1.35h13.34Z" fill="#9c9b9b" fillRule="evenodd"/>
  <path d="m106.13,15.82l1.61-8.82c.13-.73.84-1.32,1.56-1.32h21.11s.77-4.21.77-4.21h-25.24c-2.34,0-4.61,1.92-5.03,4.26l-2.07,11.31c-.43,2.34,1.14,4.26,3.48,4.26h25.24l.76-4.15h-21.11c-.73,0-1.21-.59-1.08-1.32h0Z" fill="#9c9b9b" fillRule="evenodd"/>
  <path d="m106.13,15.82l1.61-8.82c.13-.73.84-1.32,1.56-1.32h21.11s.77-4.21.77-4.21h-25.24c-2.34,0-4.61,1.92-5.03,4.26l-2.07,11.31c-.43,2.34,1.14,4.26,3.48,4.26h25.24l.76-4.15h-21.11c-.73,0-1.21-.59-1.08-1.32h0Z" fill="#9c9b9b" fillRule="evenodd"/>
  <path d="m71.3,21.44h-8.42l3.65-19.87h8.42l-3.65,19.87h0Zm23.63,0h-10.61l-9.81-9.08c-.65-.6-.67-1.22.35-1.92l12.88-8.87h10.26l-15.29,9.53,12.22,10.34h0ZM66.53,1.57h8.42l-3.64,19.82h-8.42l3.64-19.82h0Z" fill="#9c9b9b"/>
  <path d="m71.3,21.44h-8.42l3.65-19.87h8.42l-3.65,19.87h0Zm23.63,0h-10.61l-9.81-9.08c-.65-.6-.67-1.22.35-1.92l12.88-8.87h10.26l-15.29,9.53,12.22,10.34h0ZM66.53,1.57h8.42l-3.64,19.82h-8.42l3.64-19.82h0Z" fill="#9c9b9b"/>
  <polygon points="0 5.78 .76 1.57 30 1.57 29.23 5.78 18.02 5.78 15.15 21.49 7.88 21.49 10.75 5.78 0 5.78 0 5.78" fill="#9c9b9b" fillRule="evenodd"/>
  <polygon points="0 5.78 .76 1.57 30 1.57 29.23 5.78 18.02 5.78 15.15 21.49 7.88 21.49 10.75 5.78 0 5.78 0 5.78" fill="#9c9b9b" fillRule="evenodd"/>
  <path d="m43.66,8.17h17.38l-.74,3.9h-17.38l.74-3.9h0Zm-4.44,6.41l1.67-8.82c.14-.73.84-1.32,1.57-1.32h21.1s.76-4.21.76-4.21h-25.19c-2.34,0-4.62,1.92-5.06,4.26l-2.14,11.31c-.44,2.34,1.11,4.26,3.45,4.26h25.37s.75-4.15.75-4.15h-21.21c-.72,0-1.21-.6-1.07-1.33h0Z" fill="#b1b1b1" fillRule="evenodd" stroke="#fff" strokeMiterlimit="22.93" strokeWidth=".28"/>
  <path d="m156.21,4.35l.77-4.21h-17.01c-2.34,0-4.61,1.92-5.03,4.26l-2.07,11.31c-.43,2.34,1.14,4.26,3.48,4.26h23.1c2.34,0,4.61-1.92,5.03-4.26l2.07-11.31c.43-2.34-1.14-4.26-3.48-4.26h-2.28c-.26,1.42-.6,3.29-1.02,5.56l-.55,3.02-1.05,5.74c-.14.74-.86,1.35-1.6,1.35h-15.8c-.74,0-1.24-.61-1.11-1.35l1.6-8.76c.14-.74.86-1.35,1.6-1.35h13.34Z" fill="#b1b1b1" fillRule="evenodd" stroke="#fff" strokeMiterlimit="22.93" strokeWidth=".28"/>
  <path d="m107.47,14.49l1.61-8.82c.13-.73.84-1.32,1.56-1.32h21.11l.77-4.21h-25.24c-2.34,0-4.61,1.92-5.03,4.26l-2.07,11.31c-.43,2.34,1.14,4.26,3.48,4.26h25.24l.76-4.15h-21.11c-.73,0-1.21-.59-1.08-1.32h0Z" fill="#b1b1b1" fillRule="evenodd" stroke="#fff" strokeMiterlimit="22.93" strokeWidth=".28"/>
  <path d="m72.63,20.11h-8.42L67.86.23h8.42l-3.65,19.87h0Zm23.63,0h-10.61l-9.81-9.08c-.65-.6-.67-1.22.35-1.92L89.08.23h10.26l-15.29,9.53,12.22,10.34h0ZM67.86.23h8.42l-3.64,19.82h-8.42L67.86.23h0Z" fill="#b1b1b1" stroke="#fff" strokeMiterlimit="22.93" strokeWidth=".28"/>
  <polygon points="1.33 4.44 2.1 .23 31.33 .23 30.56 4.44 19.35 4.44 16.48 20.16 9.21 20.16 12.08 4.44 1.33 4.44 1.33 4.44" fill="#b1b1b1" fillRule="evenodd" stroke="#fff" strokeMiterlimit="22.93" strokeWidth=".28"/>
  <path d="m72.63,20.11h-8.42L67.86.23h8.42l-3.65,19.87h0Zm23.63,0h-10.61l-9.81-9.08c-.65-.6-.67-1.22.35-1.92L89.08.23h10.26l-15.29,9.53,12.22,10.34h0ZM67.86.23h8.42l-3.64,19.82h-8.42L67.86.23h0Z" fill="#3c3c3b" stroke="#fff" strokeMiterlimit="22.93" strokeWidth=".28"/>
  <path d="m43.66,8.17h17.38l-.74,3.9h-17.38l.74-3.9h0Zm-4.44,6.41l1.67-8.82c.14-.73.84-1.32,1.57-1.32h21.1s.76-4.21.76-4.21h-25.19c-2.34,0-4.62,1.92-5.06,4.26l-2.14,11.31c-.44,2.34,1.11,4.26,3.45,4.26h25.37s.75-4.15.75-4.15h-21.21c-.72,0-1.21-.6-1.07-1.33h0Z" fill="#3c3c3b" fillRule="evenodd" stroke="#fff" strokeMiterlimit="22.93" strokeWidth=".28"/>
  <path d="m185.4,19.98c.55,0,1.05,0,1.53-.01,1.3-.03,2.58-.76,3.87-2.48l7.87-9.12-2.12,11.6h7.33S207.51.14,207.51.14h-7.21l-12.84,14.63c-.47.54-.86.8-1.25.8-.39,0-.68-.26-.96-.8L177.75.14h-7.21l-3.62,19.82h7.33s2.12-11.59,2.12-11.59l4.54,9.12c.67,1.72,1.67,2.45,2.97,2.48.48.01.97,0,1.52.01h0Z" fill="#e20613" fillRule="evenodd" stroke="#fff" strokeMiterlimit="22.93" strokeWidth=".28"/>
  <path d="m156.21,4.35l.77-4.21h-17.01c-2.34,0-4.61,1.92-5.03,4.26l-2.07,11.31c-.43,2.34,1.14,4.26,3.48,4.26h23.1c2.34,0,4.61-1.92,5.03-4.26l2.07-11.31c.43-2.34-1.14-4.26-3.48-4.26h-2.28c-.26,1.42-.6,3.29-1.02,5.56l-.55,3.02-1.05,5.74c-.14.74-.86,1.35-1.6,1.35h-15.8c-.74,0-1.24-.61-1.11-1.35l1.6-8.76c.14-.74.86-1.35,1.6-1.35h13.34Z" fill="#e20613" fillRule="evenodd" stroke="#fff" strokeMiterlimit="22.93" strokeWidth=".28"/>
  <path d="m107.47,14.49l1.61-8.82c.13-.73.84-1.32,1.56-1.32h21.11l.77-4.21h-25.24c-2.34,0-4.61,1.92-5.03,4.26l-2.07,11.31c-.43,2.34,1.14,4.26,3.48,4.26h25.24l.76-4.15h-21.11c-.73,0-1.21-.59-1.08-1.32h0Z" fill="#e20613" fillRule="evenodd" stroke="#fff" strokeMiterlimit="22.93" strokeWidth=".28"/>
  <polygon points="1.33 4.44 2.1 .23 31.33 .23 30.56 4.44 19.35 4.44 16.48 20.16 9.21 20.16 12.08 4.44 1.33 4.44 1.33 4.44" fill="#3c3c3b" fillRule="evenodd" stroke="#fff" strokeMiterlimit="22.93" strokeWidth=".28"/>
</svg>
    )
}