import React from "react";
import Logo from "./Logo";
import { Link } from "gatsby";

const navigation = {
    solutions: [
      { name: 'Marketing', href: '#' },
      { name: 'Analytics', href: '#' },
      { name: 'Commerce', href: '#' },
      { name: 'Insights', href: '#' },
    ],
    support: [
      { name: 'Hardware', href: '/hardware#top' },
      { name: 'Infrastruktur', href: '/infrastruktur#top' },
      { name: 'IT-Sicherheit', href: '/it-sicherheit#top' },
      { name: 'Dienstleistungen', href: '/dienstleistungen#top' },
    ],
    company: [
      { name: 'NoSpamProxy', href: '/NoSpamProxy#top' },
      { name: 'Microsoft', href: '/Microsoft#top' },
      { name: 'Sophos', href: '/Sophos#top' },
      { name: 'Synology', href: '/Synology#top' },
     
    ],
    legal: [
      { name: 'Impressum', href: '../impressum' },
      { name: 'Datenschutzerklärung', href: '../datenschutzerklaerung#top' },
      
    ],
  }

export default function Footer() {
    return <>
        <footer className="bg-neutral-900" aria-labelledby="footer-heading">
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="mx-auto max-w-7xl px-6 py-16 sm:py-24 lg:px-20 lg:py-32">
        <div className="xl:grid xl:grid-cols-3 xl:gap-8">
          {/* <div className="bg-neutral-500 p-6 rounded-md w-1/5 flex items-center">
          <Logo/>
          </div> */}
          {/* <img
            className="h-7"
            src="https://tailwindui.com/img/logos/mark.svg?color=neutral&shade=500"
            alt="Company name"
          /> */}
          <div className="mt-16 grid grid-cols-2 gap-8 xl:col-span-2 xl:mt-0">
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                {/* <h3 className="text-sm font-semibold leading-6 text-white">Solutions</h3> */}
                <div className="bg-neutral-200 p-6 rounded-md w-4/5 flex items-center">
          <Logo/>
          </div>
                {/* <ul role="list" className="mt-6 space-y-4">
                  {navigation.solutions.map((item) => (
                    <li key={item.name}>
                      <a href={item.href} className="text-sm leading-6 text-gray-300 hover:text-white">
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul> */}
              </div>
              <div className="mt-10 md:mt-0">
                <h3 className="text-sm font-semibold leading-6 text-white">Leistungen</h3>
                <ul role="list" className="mt-6 space-y-4">
                  {navigation.support.map((item) => (
                    <li key={item.name}>
                      <Link to={item.href} className="text-sm leading-6 text-gray-300 hover:text-white">
                        {item.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-sm font-semibold leading-6 text-white">Anbieter</h3>
                <ul role="list" className="mt-6 space-y-4">
                  {navigation.company.map((item) => (
                    <li key={item.name}>
                      <Link to={item.href} className="text-sm leading-6 text-gray-300 hover:text-white">
                        {item.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="mt-10 md:mt-0">
                <h3 className="text-sm font-semibold leading-6 text-white">Rechtliche Angaben</h3>
                <ul role="list" className="mt-6 space-y-4">
                  {navigation.legal.map((item) => (
                    <li key={item.name}>
                      <a href={item.href} className="text-sm leading-6 text-gray-300 hover:text-white">
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>




    </footer>
    </>
}