exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-alt-js": () => import("./../../../src/pages/alt.js" /* webpackChunkName: "component---src-pages-alt-js" */),
  "component---src-pages-collection-js": () => import("./../../../src/pages/collection.js" /* webpackChunkName: "component---src-pages-collection-js" */),
  "component---src-pages-datenschutzerklaerung-js": () => import("./../../../src/pages/datenschutzerklaerung.js" /* webpackChunkName: "component---src-pages-datenschutzerklaerung-js" */),
  "component---src-pages-general-page-js": () => import("./../../../src/pages/generalPage.js" /* webpackChunkName: "component---src-pages-general-page-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-ueberuns-js": () => import("./../../../src/pages/ueberuns.js" /* webpackChunkName: "component---src-pages-ueberuns-js" */),
  "component---src-templates-partner-js": () => import("./../../../src/templates/Partner.js" /* webpackChunkName: "component---src-templates-partner-js" */),
  "component---src-templates-seite-js": () => import("./../../../src/templates/Seite.js" /* webpackChunkName: "component---src-templates-seite-js" */)
}

