import React from "react";
import  SanityImage  from "gatsby-plugin-sanity-image";
import { PortableText } from "@portabletext/react";
import PortableTextComponents from "./Portable";


const TekHeader = (header) => {

    return (
        <div className="relative isolate overflow-hidden bg-gradient-to-b from-red-100/20 pt-14">
        <div
          className="absolute inset-y-0 right-1/2 -z-10 -mr-96 w-[200%] origin-top-right skew-x-[-30deg] bg-gray-200 shadow-xl shadow-red-600/10 ring-1 ring-red-50 sm:-mr-80 lg:-mr-96"
          aria-hidden="true"
        />
        <div className="mx-auto max-w-7xl px-6 py-32 sm:py-40 lg:px-8">
          <div className="mx-auto max-w-2xl lg:mx-0 lg:grid lg:max-w-none lg:grid-cols-2 lg:gap-x-16 lg:gap-y-6 xl:grid-cols-1 xl:grid-rows-1 xl:gap-x-8">
            <h1 className="max-w-2xl text-4xl font-bold tracking-tight text-gray-700 sm:text-6xl lg:col-span-2 xl:col-auto">
              {header.headline}
            </h1>
            <div className="mt-6 max-w-xl lg:mt-0 xl:col-end-1 xl:row-start-1">
              <p className="text-lg leading-8 text-gray-600 mr-2 md:mr-12">
              <PortableText 
        value = {header.tagline} 
        components={PortableTextComponents}/>
              </p>
              <div className="mt-10 flex items-center gap-x-6">
                {/* <a
                  href="#"
                  className="rounded-md bg-red-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                >
                  Get started
                </a> */}
                <a href="#main" className="text-sm font-semibold leading-6 text-red-600">
                  mehr erfahren <span aria-hidden="true">→</span>
                </a>
              </div>
            </div>
            <div className="relative z-20 mt-10 w-[450px] max-w-lg max-h-[450px] sm:mt-16 lg:mt-0 lg:max-w-none xl:row-span-2 xl:row-end-2 xl:mt-36  justify-self-end bg-transparent mr-24">
            <div className="absolute z-40 inset-0 w-full h-full  bg-gray-600/40 rounded-2xl "></div>
            <SanityImage {...header.image} width={450} height={450} style={{
              width: "450px",
              height: "450px",
              objectFit: "cover"}}
              alt="none"
              loading="eager" 
              className="z-10 aspect-[6/5]  rounded-2xl object-cover  shadow-xl"
              />

            
              </div>
          </div>
        </div>
        <div className="absolute inset-x-0 bottom-0 -z-10 h-24 bg-gradient-to-t from-white sm:h-48" />
      </div>
    )


}


export default TekHeader;